// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(req) {
  return Json_Encode$JsonCombinators.object([[
                "active",
                req.active
              ]]);
}

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              providers: field.required("providers", Json_Decode$JsonCombinators.array(Provider.Filter.decoder))
            };
    });

var Res = {
  decoder: decoder
};

function exec(active, query) {
  return Rest.$$fetch("/providers/location_brochures_filter?query=" + query, {
              NAME: "Post",
              VAL: toJson({
                    active: active
                  })
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
