// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function toString(x) {
  if (x === "Desc") {
    return "desc";
  } else {
    return "asc";
  }
}

var SortDirection = {
  toString: toString
};

var SortBy = {};

var Req = {
  SortDirection: SortDirection,
  SortBy: SortBy
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              locations: field.required("locations", Json_Decode$JsonCombinators.array($$Location.Dashboard.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function exec(providerId, pageNum, query, sortBy) {
  var page = "page=" + String(pageNum);
  var query$1 = Belt_Option.getWithDefault(Belt_Option.map(query, (function (x) {
              return "&query=" + encodeURIComponent(x);
            })), "");
  var variant = sortBy.NAME;
  var sortBy$1 = variant === "State" ? "&sort_by=state&sort_direction=" + toString(sortBy.VAL) : (
      variant === "City" ? "&sort_by=city&sort_direction=" + toString(sortBy.VAL) : (
          variant === "Name" ? "&sort_by=name&sort_direction=" + toString(sortBy.VAL) : "&sort_by=country&sort_direction=" + toString(sortBy.VAL)
        )
    );
  return Rest.$$fetch("/dashboard/providers/" + ID.toString(providerId) + "/locations?" + (page + (query$1 + sortBy$1)), "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
