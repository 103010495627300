// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(id) {
  return Json_Encode$JsonCombinators.object([[
                "id",
                ID.toJson(id)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(providerDraftId) {
  var url = "/dashboard/provider_drafts/" + ID.toString(providerDraftId) + "/approve";
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: toJson(providerDraftId)
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
