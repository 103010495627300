// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              locations: field.required("locations", Json_Decode$JsonCombinators.array(Provider.$$Location.decoder)),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int)
            };
    });

var Res = {
  decoder: decoder
};

function queryString(pageNum, perPage) {
  return "page=" + (String(pageNum) + ("&per_page=" + String(perPage)));
}

function exec(pageNum, perPage, providerId, param) {
  var url = "/providers/" + ID.toString(providerId) + "/locations_show?" + queryString(pageNum, perPage);
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  queryString ,
  exec ,
}
/* decoder Not a pure module */
