// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Validator from "Utils/validator";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function empty(param) {
  return {
          name: ""
        };
}

function toJson(input) {
  return Json_Encode$JsonCombinators.object([[
                "name",
                input.name
              ]]);
}

var Input = {
  empty: empty,
  toJson: toJson
};

function toJs(input) {
  return {
          name: input.name
        };
}

var ValidationInput = {
  toJs: toJs
};

function fromJs(js) {
  return {
          name: Caml_option.nullable_to_opt(js.name)
        };
}

var ExternalValidationResult = {
  fromJs: fromJs
};

function convertResult(field) {
  if (field !== undefined) {
    return {
            TAG: "Error",
            _0: Caml_option.valFromOption(field)
          };
  } else {
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
}

function fromExternalResult(results) {
  return {
          name: convertResult(results.name)
        };
}

var ValidationResult = {
  convertResult: convertResult,
  fromExternalResult: fromExternalResult
};

var validationFields = [{
    id: "name",
    validation: [
      {
        TAG: "BasicRule",
        _0: {
          rule: "required",
          message: "Provider name is required."
        }
      },
      {
        TAG: "BasicRule",
        _0: {
          rule: "empty",
          message: "Enter valid provider name."
        }
      }
    ]
  }];

function fieldsToJs(fields) {
  return Belt_Array.map(fields, (function (item) {
                return {
                        id: item.id,
                        validation: Belt_Array.map(item.validation, (function (rule) {
                                return rule._0;
                              }))
                      };
              }));
}

function all(values) {
  var js = Validator.formErrorsValidations(toJs(values), fieldsToJs(validationFields));
  return fromExternalResult({
              name: Caml_option.nullable_to_opt(js.name)
            });
}

function valid(result) {
  if (result.name.TAG === "Ok") {
    return true;
  } else {
    return false;
  }
}

var Validate = {
  validationFields: validationFields,
  fieldsToJs: fieldsToJs,
  all: all,
  valid: valid
};

export {
  Input ,
  ValidationInput ,
  ExternalValidationResult ,
  ValidationResult ,
  Validate ,
}
/* Utils/validator Not a pure module */
