// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as CreateProviderData from "../../../bundles/dashboard-create-provider/CreateProviderData.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var toJson = CreateProviderData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return field.required("provider", Provider.Slug.decoder);
    });

var Res = {
  decoder: decoder
};

function exec(input) {
  return Rest.$$fetch("/dashboard/providers", {
              NAME: "Post",
              VAL: toJson(input)
            }, {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
