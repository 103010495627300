// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";

function exec(providerId, imageId) {
  return Rest.$$fetch("/dashboard/providers/" + (ID.toString(providerId) + ("/images/" + ID.toString(imageId))), {
              NAME: "Delete",
              VAL: undefined
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* ID Not a pure module */
