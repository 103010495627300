// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";

function exec() {
  return Rest.$$fetch("/providers/all", "Get", {
              NAME: "Json",
              VAL: Provider.List.decoder
            }, undefined, undefined);
}

export {
  exec ,
}
/* Rest Not a pure module */
