// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              valid: field.required("valid", Json_Decode$JsonCombinators.bool),
              message: field.required("message", Json_Decode$JsonCombinators.string)
            };
    });

var Res = {
  decoder: decoder
};

function exec(locationId, providerId, name) {
  var url = "/dashboard/providers/" + ID.toString(providerId) + "/location_name_validation?name=" + name + (
    locationId !== undefined ? "&location_id=" + ID.toString(Belt_Option.getExn(Caml_option.some(Caml_option.valFromOption(locationId)))) : ""
  );
  return Rest.$$fetch(url, "Get", {
              NAME: "Json",
              VAL: decoder
            }, undefined, undefined);
}

export {
  Res ,
  exec ,
}
/* decoder Not a pure module */
