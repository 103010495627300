// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Provider from "../../../models/Provider.res.js";
import * as ProviderDraft from "../../../models/ProviderDraft.res.js";

var Req = {
  toJson: ProviderDraft.toJson
};

function exec(providerDraftId, providerDraft) {
  var url = "/dashboard/provider_drafts/" + ID.toString(providerDraftId);
  return Rest.$$fetch(url, {
              NAME: "Patch",
              VAL: ProviderDraft.toJson(providerDraft)
            }, {
              NAME: "Json",
              VAL: Provider.Edit.decoder
            }, undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
